#fistColumn{
    background: fixed;
    background-color: white;
    position: fixed;
    height: 100%;
    top: 0px;
    width: 6.5rem;
    /* width: 5.5%; */
    transition: .5s ease;
}
#fistColumn.open {
    width: 14rem;
    transition: .5s ease;
}

#secondColumn{
    height: 100%;
    margin-left: 10rem;
    transition: .5s ease;
}

#secondColumn.open{
    margin-left: 16rem;
    transition: .5s ease;
}
@media screen and (width <=1024px) {
	#fistColumn {
		width: 0rem;
	}
    #secondColumn{
        margin-left: 2rem;
        transition: .5s ease;
    }
}

.toggle-sidebar-div:hover{
    background-color: #F2EFFF;
    border-radius: 1rem;
}
.header{
    font-weight: bold;
    margin-left: 2rem;
    /* display: flex;
    justify-content: center; */
}
.Dashboard{
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

/* Start Card Component */

.Cards{
    display: flex;
    justify-content: center;
}

.ParentContainer{
    width: 100%;
}
.wrapper{
    gap: 1rem;
    justify-content: left; 
    /* justify-content: center;    */
}
#card{
    border: none;
    background-color: white;
    margin-top: 2rem;
    border-radius: 30px;
    width: 30%;
}
@media screen and (width <= 1000px) {
    #card{
        width: 80%;
    }
}
#cardBody{
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
#cardBody2{
    display: flex;
    flex-direction: column;
}

#cardIcon1{
    color: white;
    background-color: rgb(255,135,0);
    width: 60px;
    height: 60px;
    /* padding: 1rem; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

#cardIcon2{
    color: rgb(187,107,217);
    background-color: rgb(241,225,247);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

#cardIcon3{
    color: rgb(62,0,194);
    background-color: rgb(234,230,255);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
#Number{
    font-size: 24px;
    font-weight: bold;
}

#title{
    margin-left: 1rem;
    margin-right: 1rem;
}

#status{
    color : green;
    font-size: 12px;
}

/* End Card Component */

.yearly-chart{
    padding:2rem;
    width: 95%;
    height: auto;
    margin: auto;
}

@media screen and (width <= 700px) {
    .yearly-chart{
        width: 95%;
        height: auto;
    }
}

.email-grids{
    display: grid !important;
    grid-template-columns: 70% 30%;
  }
.email-img{
    width: 90%;
    height: auto;
}
  
  @media screen and (width <= 1024px) {
    .email-grids{
      grid-template-columns:none;
      grid-template-rows: 70% 30%!important;
    }
    .email-img{
        width: 60%;
        height: auto;
        padding: 2rem;
    }
  }


/* Profile page Styles */
.emp-profile{
    padding: 2rem;
    background-color: white;
    margin: auto;
    box-shadow: 1px 0 5px 0 rgb(128,133,227);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

